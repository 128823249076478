import img1 from "../images/business/starbucks-coffee-&-country-czech-bakery-23923-65505.jpg";
import img2 from "../images/business/shell-station-24613-70704.jpg";
import img3 from "../images/business/chasebank-24544-70735.jpg";
import img4 from "../images/business/wells-fargo-24521-70813.jpg";
import img5 from "../images/business/applebees-24157-1-71019.jpg";
import img6 from "../images/business/corporate-arby-s-24323-1-70921.jpg";
import img7 from "../images/business/burger-king-24228-1-70951.jpg";
import img8 from "../images/business/lennox-station-retail-center-24029-1-71037.jpg";
import img9 from "../images/business/starbucks-anchored-retail-condo-24005-1-71255.jpg";

export const business = [
  {
    id: 1,
    title: "Starbucks Coffee & Country Czech Bakery",
    img: img1,
    location: "Texas",
    salePrice: "1,669,900",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
  {
    id: 2,
    title: "Shell Station",
    img: img2,
    location: "California",
    salePrice: "32,989,000",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
  {
    id: 3,
    title: "Chasebank",
    img: img3,
    location: "Florida",
    salePrice: "3,235,293",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
  {
    id: 4,
    title: "Wells Fargo",
    img: img4,
    location: "Georgia",
    salePrice: "2,793,120",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
  {
    id: 5,
    title: "Applebee’s",
    img: img5,
    location: "Florida",
    salePrice: "4,068,900",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
  {
    id: 6,
    title: "Applebee’s",
    img: img5,
    location: "Texas",
    salePrice: "3,258,200",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
  {
    id: 7,
    title: "Corporate Arby's",
    img: img6,
    location: "Michigan",
    salePrice: "2,365,000",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
  {
    id: 8,
    title: "Burger King",
    img: img7,
    location: "Illinois",
    salePrice: "1,148,807",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
  {
    id: 9,
    title: "Applebee's",
    img: img5,
    location: "California",
    salePrice: "4,728,900",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
  {
    id: 10,
    title: "Lennox Station Retail Center",
    img: img8,
    location: "Ohio",
    salePrice: "5,346,000",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
  {
    id: 11,
    title: "Starbucks Anchored Retail Condo",
    img: img9,
    location: "Illinois",
    salePrice: "2,500,300",
    rentalIncome: "***",
    depreciationPeriod: "***",
  },
];
