import "./App.css";
import "./css/bootstrap.css";
import "./css/flexslider.css";
import "./css/font-awesome.css";
import "./css/templatemo_misc.css";
import "./css/templatemo_style.css";
import "./css/testimonails-slider.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./pages/homepage";
import Header from "./components/header";
import Footer from "./components/footer";
import { stack as Menu } from "react-burger-menu";
import Kurumsal from "./pages/kurumsal";
import Avantajlar from "./pages/avantajlar";
import Portfoy from "./pages/portfoy";
import Business from "./pages/business";
import Flip from "./pages/flip";
import Academy from "./pages/academy";
import Faq from "./pages/faq";
import Blog from "./pages/blog";
import BlogDetail from "./pages/blogDetail";
import BasindaBiz from "./pages/basindaBiz";
import Galeri from "./pages/galeri";
import Sertifika from "./pages/sertifika";
import Iletisim from "./pages/iletisim";
import Katalog from "./pages/katalog";
import ScrollToTop from "./helper/ScrollToTop";
import Modal from "./components/modal";
import OrganizationSchema from "./pages/organizationSchema";
import PrivacyPolicy from "./pages/privacyPolicy";
import CookiePolicy from "./pages/cookiePolicy";
import PageNotFound from "./pages/pageNotFound";

function App() {
  return (
    <Router>
      <Menu
        right
        disableAutoFocus
        pageWrapId={"root"}
        outerContainerId={"root"}
      >
        <a className="menu-item" href="/">
          Homepage
        </a>
        <a className="menu-item" href="/corporate">
          Corporate
        </a>
        <a className="menu-item" href="/we-in-the-press">
          We in The Press
        </a>
        <a className="menu-item" href="/flip">
          Flip
        </a>
        <a className="menu-item" href="/academy">
          Academy
        </a>
        <a className="menu-item" href="/advantages">
          Advantages
        </a>
        <a className="menu-item" href="/portfolio">
          Portfolio
        </a>
        <a className="menu-item" href="/business">
          Business Line
        </a>
        <a className="menu-item" href="/communication">
          Communication
        </a>
      </Menu>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/corporate" element={<Kurumsal />} />
        <Route path="/advantages" element={<Avantajlar />} />
        <Route path="/portfolio" element={<Portfoy />} />
        <Route path="/business" element={<Business />} />
        <Route path="/flip" element={<Flip />} />
        <Route path="/academy" element={<Academy />} />
        <Route path="/buying-a-house-in-america" element={<Faq />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/we-in-the-press" element={<BasindaBiz />} />
        <Route path="/gallery" element={<Galeri />} />
        <Route path="/certificates" element={<Sertifika />} />
        <Route path="/communication" element={<Iletisim />} />
        <Route path="/catalogs" element={<Katalog />} />
        <Route path="/organization-schema" element={<OrganizationSchema />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
      <Modal />
    </Router>
  );
}

export default App;
