import React from "react";
import WSPGallery from "../../components/WSPGallery/WSPGallery";
import img1 from "../../images/sertifika/trusa-new1-60032_8102018154609.jpg";
import img2 from "../../images/sertifika/erdem-sezer1-60117_8102018154637.jpg";
import img3 from "../../images/sertifika/greltrusa-logo-60716_8102018154758.jpg";
import img4 from "../../images/sertifika/hardvardx_19062021174126.jpg";

function Sertifika() {
  let images = [
    {
      img: img1,
      fullScreenImg: img1,
    },
    {
      img: img2,
      fullScreenImg: img2,
    },
    {
      img: img3,
      fullScreenImg: img3,
    },
    {
      img: img4,
      fullScreenImg: img4,
    },
  ];

  return (
    <div className="container basin-content">
      <h3>SERTİFİKALAR</h3>
      <WSPGallery galleryImages={images} />
    </div>
  );
}

export default Sertifika;
