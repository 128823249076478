import React, { useEffect } from "react";
import "./sliderHomepage.scss";
// import slider1 from "../../images/perfect-usa-home-2021-08-26-22-29-35-utc.jpg";
// import slider2 from "../../images/old-wooden-house-2022-07-05-12-42-46-utc.jpg";
// import slider3 from "../../images/slide3.jpg";
// import { Fade } from "react-slideshow-image";
import Aos from "aos";

function SliderHomepage() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  // const FadeProperties = {
  //   duration: 5000,
  //   pauseOnHover: true,
  //   transitionDuration: 500,
  //   infinite: true,
  //   arrows: true,
  // };
  // const imageDesc = [
  //   {
  //     id: 1,
  //     content: {
  //       header: "",
  //       cnt: "",
  //     },
  //   },
  //   {
  //     id: 2,
  //     content: {
  //       header: "",
  //       cnt: "",
  //     },
  //   },
  //   {
  //     id: 3,
  //     content: {
  //       header: "Healthy Drinks",
  //       cnt: "Maecenas fermentum est ut elementum vulputate. Ut vel consequaturna. Ut aliquet <br /><br />ornare massa, quis dapibus quamcondimentum id.",
  //     },
  //   },
  // ];
  // const images = [slider1, slider2, slider3];
  return (
    <div data-aos="fade-up" className="slide-container">
      <div className="each-slide">
        <iframe
          width="100%"
          className="home-slider-youtube"
          src="https://www.youtube.com/embed/F7GRysWyhPU?autoplay=1&mute=1&loop=1&playlist=F7GRysWyhPU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      {/* <Fade {...FadeProperties}>
        {images.map((each, index) => (
          <div key={index} className="each-slide">
            <img className="slide-img" alt="" src={each} />
            <div className="slider-caption">
              <h1>{imageDesc[index].content.header}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: imageDesc[index].content.cnt,
                }}
              ></p>
            </div>
          </div>
        ))}
      </Fade> */}
    </div>
  );
}

export default SliderHomepage;
