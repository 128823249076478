import React, { useEffect } from "react";
import "./blogpost.scss";
import Aos from "aos";
import img1 from "../../images/blog-post/ilk-adim-93908.jpg";
import img2 from "../../images/blog-post/ilk-adim-2-93920.jpg";
import img3 from "../../images/blog-post/ilk-adim-3-93928.jpg";

function BlogPost() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div data-aos="fade-bottom" id="latest-blog">
      <div className="container">
        <div className="blog-post-div">
          <div className="blog-post-cell">
            <div className="blog-post-img">
              <img src={img1} alt="blogPost" />
            </div>
            <div className="blog-post-desc-div">
              <span>101</span>
              <h4>FIRST STEP TO BUY A HOUSE IN AMERICA</h4>
              <p>
                By filling out the form in this section, you will have taken the
                first step to buy a house in America. Our real estate
                consultants will guide you to make the right investment.
              </p>
            </div>
          </div>
          <div className="blog-post-cell">
            <div className="blog-post-img">
              <img src={img2} alt="blogPost" />
            </div>
            <div className="blog-post-desc-div">
              <span>102</span>
              <h4>GET A HOME IN 7 SHORT STEPS!</h4>
              <p>
                Congrats on your new home. Your deed is now in your name. We
                receive our keys or deliver them to our tenant. It will be
                credited to your account within 2 business days immediately
                after the completion of the sale.
              </p>
            </div>
          </div>
          <div className="blog-post-cell">
            <div className="blog-post-img">
              <img src={img3} alt="blogPost" />
            </div>
            <div className="blog-post-desc-div">
              <span>103</span>
              <h4>
                CONGRATULATIONS! YOU HAVE COMPLETED THE PROCESS, YOUR DEED AND
                YOUR RENTAL IS READY!
              </h4>
              <p>
                We have listed 7 steps for you to buy a house in America. In
                this process, Trusa Investment Management Team is with you in
                all steps…
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
