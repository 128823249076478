/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./modal.scss";
import Aos from "aos";
import Swal from "sweetalert2";
import axios from "axios";
function Modal() {
  const [isModalActive, setIsModalActive] = useState(true);
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  function closeModal() {
    setIsModalActive(false);
  }
  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
  function sendModalForm() {
    let syc = 0;
    let veri = new FormData(document.getElementById("modalForm"));
    console.log(Object.fromEntries(veri));
    var formData = new FormData(document.getElementById("modalForm"));
    for (var pair of formData.entries()) {
      if (pair[1] === "" && pair[0] !== "companyNameModal") {
        Swal.fire("", "Please fill in the required fields", "error");
        syc++;
      } else if (pair[0] === "epostaModal" && !validateEmail(pair[1])) {
        Swal.fire("", "Please enter a valid email", "error");
        syc++;
      }
    }

    if (syc === 0) {
      let data = formData;
      axios({
        method: "post",
        url: "https://api.trusa.qa/mail/modal/",
        data: data,
      })
        .then((response) => {
          console.log(response);
          if (response.data.isSuccess) {
            Swal.fire(
              "",
              "Your request has been sent successfully.",
              "success"
            );
          } else {
            Swal.fire("", `${response.data.mesaj}`, "error");
          }
        })
        .catch((err) => {
          Swal.fire(
            "",
            "Your request could not be sent. Please try again later.",
            "error"
          );
        });
    }
  }

  return (
    <>
      {isModalActive ? (
        <div data-aos="fade-bottom" className="modal-div">
          <div className="modal-close">
            <p onClick={() => closeModal()}>X</p>
          </div>
          <br />
          <div className="modal-title">How Can I Help You ?</div>
          <form id="modalForm" className="modal-input-div">
            <input
              type="text"
              maxLength={50}
              name="adSoyadModal"
              placeholder="Name*"
            />
            <input
              type="text"
              maxLength={50}
              name="companyNameModal"
              placeholder="Company Name"
            />
            <input
              type="text"
              maxLength={50}
              name="epostaModal"
              placeholder="E-Mail Adress*"
            />
            <input
              type="number"
              maxLength={20}
              name="telModal"
              placeholder="Phone Number*"
            />
            <div className="modal-submit-div">
              <a onClick={() => sendModalForm()} className="modal-submit-btn">
                Send
              </a>
            </div>
          </form>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Modal;
