import React from "react";
import "./portfoy.scss";
import { Parallax } from "react-parallax";
import parallaxBgImage from "../../images/portfoyBanner-1.jpg";
import parallaxLeftContentImg from "../../images/yazi-portfoy.png";
import parallaxRightContentImg from "../../images/portfoyBayrak.png";
import kiralik1 from "../../images/trusa-ev-fiyatlari-01.jpg";
function Portfoy() {
  return (
    <>
      <div style={{ position: "relative" }}>
        <Parallax bgImage={parallaxBgImage} bgImageAlt="city" strength={200}>
          <div className="container parallax-div">
            <img src={parallaxLeftContentImg} alt="description" />
            <img src={parallaxRightContentImg} alt="flag" />
          </div>
        </Parallax>
      </div>
      <div className="container">
        <div className="portfoy-title">
          <b>PORTFÖY</b>
        </div>
        <p className="portfoy-desc">
          <b>
            SOME ADVERTISEMENTS SOLD IN TRUSA PORTFOLIO ARE GIVEN BELOW.
            <br />
            FOR OUR PORTFOLIO OPTIONS CALL US{" "}
            <a style={{ color: "#09f" }} href="tel:+97433030256">
              +974 3303 0256
            </a>{" "}
            AND WE WILL SHARE OUR CURRENT PORTFOLIO.
          </b>
        </p>
        <div className="portfoy-grid">
          <div>
            <img src={kiralik1} alt="kiralık ev" />
          </div>
          <div>
            <img src={kiralik1} alt="kiralık ev" />
          </div>
          <div>
            <img src={kiralik1} alt="kiralık ev" />
          </div>
          <div>
            <img src={kiralik1} alt="kiralık ev" />
          </div>
          <div>
            <img src={kiralik1} alt="kiralık ev" />
          </div>
          <div>
            <img src={kiralik1} alt="kiralık ev" />
          </div>
          <div>
            <img src={kiralik1} alt="kiralık ev" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Portfoy;
