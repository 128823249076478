import React from "react";
import WSPGallery from "../../components/WSPGallery/WSPGallery";
import "./basindaBiz.scss";

function BasindaBiz() {
  let images = [
    {
      img: "https://img.youtube.com/vi/gOVJJwKSAJM/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/gOVJJwKSAJM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/1Y5E1Ezx2Uk/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/1Y5E1Ezx2Uk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/c7esJfcAm8A/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/c7esJfcAm8A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/E6uuCbIHQxU/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/E6uuCbIHQxU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/MZ-IjduL5ds/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/MZ-IjduL5ds" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/_2QiO1LeXsE/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/_2QiO1LeXsE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/OA2igZpq5dw/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/OA2igZpq5dw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/lpHr1hXKvcY/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/lpHr1hXKvcY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/LKwLU54tKk0/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/LKwLU54tKk0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/mhboSaQ1q3c/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/mhboSaQ1q3c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/F-yFDX6sqEs/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/F-yFDX6sqEs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
    {
      img: "https://img.youtube.com/vi/4aqmpdibEPI/0.jpg",
      fullScreenImg:
        '<iframe width="70%" height="70%" src="https://www.youtube.com/embed/4aqmpdibEPI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    },
  ];
  return (
    <div className="container basin-content">
      <h3>We in The Press</h3>
      <WSPGallery galleryImages={images} />
    </div>
  );
}

export default BasindaBiz;
