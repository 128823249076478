import React from "react";
import "./avantajlar.scss";
import img1 from "../../images/advantage-img1.png";
import home from "../../images/ev.png";

function Avantajlar() {
  return (
    <div className="advantage-first-area">
      <div className=" container">
        <img src={img1} alt="Trusa advantage" />
        <div className="advantage-first-desc">
          <h1>TRUSA ADVANTAGES</h1>
          <p>
            TRUSA Investment Management, Amerika çapında oluşturduğu alt yapı
            ile her türlü yatırım fırsatına Türkiye’den ulaşımı mümkün
            kılabilmektedir. İstediğiniz mülk ister ev, ofis, arsa, işyeri,
            banka şubesi, restaurant veya dünya devi bir markanın mülkü olsun
            tüm yatırım araçlarına TRUSA ile ulaşabilmeniz mümkündür.
            <br />
            <br /> TRUSA vasıtası ile satın almış olduğunuz yatırımınızı zaman
            içerisinde tekrar satmak isterseniz, satış konusunda da TRUSA
            yanınızda olacaktır.
          </p>
        </div>
        <div className="advantage-second-area">
          <img src={home} alt="home" />
          <div className="advantage-second-desc">
            <ul>
              <li>
                <p>
                  The title deed of your real estate is issued in your name.
                  First, the copy will be sent to you by e-mail, and then the
                  original will be sent to you by mail.
                </p>
              </li>
              <li>
                <p>
                  You will have a rental income nearly three times more than the
                  rent income you can get in Turkey.
                  <br /> (with the rent guarantee option)
                </p>
              </li>
              <li>
                <p>
                  All the needs of your house such as renting, maintenance and
                  follow-up are provided by TRUSA professionals in America.
                </p>
              </li>
              <li>
                <p>
                  As of 2018 TRUSA manages the properties that it assists
                  purchasing with TRUSA INVESTMENT LLC Property Management
                  Company from their offices in 5 different states with their
                  own teams.
                </p>
              </li>
              <li>
                <p>
                  In that way you will never pay high amounts of fees to
                  subcontractors for the maintenance of your property, you can
                  get TRUSA team’s service.
                </p>
              </li>
              <li>
                <p>
                  Your rent is deposited in US Dollars to your bank account in
                  the country you specify.
                </p>
              </li>
              <li>
                <p>
                  You can be directed to experienced professionals for residence
                  permit and citizenship applications services in America
                  according to your needs. With a team of 71 professionals in 5
                  offices in America, we provide a real estate portfolio of the
                  highest profit investment options to our investors.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="advantage-third-area">
        <div className="container">
          <div className="advantage-third-desc">
            <h4>An Investment Opportunity Sample;</h4>
            <p>
              With Trusa, an average rent income of a house you will purchase in
              America ($60.000) after the rent guarantee commission is deducted
              brings <b>$450-$500</b> a month which is nearly three times more
              than the rent income you will get in Turkey. The example above is
              not given to attract investors, it is one of the investment
              options of TRUSA.
              <br />
              <br />
              <b>Your Annual Expenses;</b>
              <br />
              1. Real Estate Tax (Approximately one month rent income)
              <br />
              2. Natural Disaster Insurance (Approximately one month rent
              income)
              <br />
              The Return of Investment of a property you purchase from America
              will be around 11-12 years in US Dollars including all the
              expenses (taxes, natural disaster insurance and rent guarantee
              commision)
              <br /> In other words, your $60.000 investment will make net
              $4.500-$5.000 a year.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Avantajlar;
