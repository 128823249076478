import React from "react";
import "./organization.scss";
import { Parallax } from "react-parallax";
import bg from "../../images/statsbg.png";
import flag1 from "../../images/organization/turkey.png";
import flag2 from "../../images/organization/usa.png";
import flag3 from "../../images/organization/flip.png";

function OrganizationSchema() {
  return (
    <>
      <div className="organization" style={{ position: "relative" }}>
        <Parallax pages={3} bgImage={bg} bgImageAlt="city" strength={300}>
          {/* <Background className="custom-bg">
            <img src={bg} alt="parallax img" />
          </Background> */}
          <div className="organization-desc-div">
            <div
              style={{ borderRight: "2px solid #e6cf8e", paddingRight: "20px" }}
            >
              <span
                style={{
                  color: "#e6cf8e",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                TURKEY & AMERICA
              </span>
              <br />
              <span
                style={{
                  color: "#fff",
                  fontSize: "25px",
                  fontWeight: "600",
                }}
              >
                PREFESSIONAL
                <br /> EXPERT STAFF
              </span>
            </div>
            <div className="organization-sub-div">
              <div className="organization-desc-flag-div">
                <img src={flag1} alt="flag" />
                <div>
                  <span
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                  >
                    ISTANBUL OFFICE
                  </span>
                  <br />
                  <span
                    style={{
                      color: "#e6cf8e",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    20+ PERSON
                  </span>
                </div>
              </div>
              <div className="organization-desc-flag-div">
                <img src={flag2} alt="flag" />
                <div>
                  <span
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                  >
                    AMERICA OFFICE
                  </span>
                  <br />
                  <span
                    style={{
                      color: "#e6cf8e",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    51+ PERSON
                  </span>
                </div>
              </div>
              <div className="organization-desc-flag-div">
                <img src={flag3} alt="flag" />
                <div>
                  <span
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                  >
                    TRUSA AMERICA FLIP TEAM
                  </span>
                  <br />
                  <span
                    style={{
                      color: "#e6cf8e",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    17+ PERSON
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>

      <section class="page pad-80 ekip">
        <div class="container">
          <div class="ekip">
            <ul class="ekip-main has-three">
              <li class="has-two ekip-mainentry">
                <div class="ekip-main-title">
                  <div class="name">
                    Erdem SEZER{" "}
                    <span style={{ textAlign: "center" }}> CEO </span>
                  </div>
                </div>

                <ul class="ekip-bolum">
                  <li class="ekip-department">
                    <div class="ekip-bolum-title toptitle has-two">
                      <div class="top-title">
                        USA
                        <br />
                        HOME BUYING TEAM
                      </div>
                      <div class="name">
                        Ebru VURAL <span>Process Control Manager</span>
                      </div>

                      <div class="name">
                        Tim COOK <span>USA Team Leader</span>
                      </div>
                    </div>
                    <ul class="ekip-bolum last">
                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Rondre BROOKS <span>Michigan Team Leader</span>
                          </div>
                        </div>
                      </li>

                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Mehmet ERAYMAN <span>IST Team Leader</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="ekip-department">
                    <div class="ekip-bolum-title  toptitle has-three">
                      <div class="top-title">
                        USA REAL ESTATE
                        <br />
                        MANAGEMENT TEAM
                      </div>
                      <div class="name">
                        Ali Alp Kağan DEMİR <span>USA Team Leader</span>
                      </div>
                      <div class="name">
                        Sheneelia LOGAN <span> USA Assistant Team Leader</span>
                      </div>
                      <div class="name">
                        Emily VELKOVSKA <span> Ohio Team Leader</span>
                      </div>
                    </div>
                    <ul class="ekip-bolum last">
                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Özgür SÜRGEN{" "}
                            <span>
                              USA Repairs
                              <br />
                              Team Chief
                            </span>
                          </div>
                        </div>
                      </li>
                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Sean PHENEY{" "}
                            <span>
                              USA Repairs
                              <br />
                              Team Chief
                            </span>
                          </div>
                        </div>
                      </li>
                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Gary Allan LABELL{" "}
                            <span>
                              USA Repairs
                              <br />
                              Team Chief
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div class="clearfix"></div>
                  </li>
                  <li class="ekip-department">
                    <div class="ekip-bolum-title  toptitle has-two">
                      <div class="top-title">
                        USA TRUSA
                        <br />
                        HOMES “FLIP” TEAM
                      </div>
                      <div class="name">
                        Shannon O'BRIEN <span>USA Team Leader</span>
                      </div>
                      <div class="name">
                        Gabriel ADAMS{" "}
                        <span>USA Ekip Assistant Team Leader</span>
                      </div>
                    </div>

                    <ul class="ekip-bolum last">
                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Ata ERAN <span>Process Manager</span>
                          </div>
                        </div>
                      </li>

                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Özgün ERDİN <span>Process Manager</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="ekip">
            <ul class="ekip-main has-three">
              <li class="has-two ekip-mainentry">
                <div class="ekip-main-title">
                  <div class="user card-on"></div>
                  <div class="name"></div>
                </div>

                <ul class="ekip-bolum">
                  <li class="ekip-department">
                    <div class="ekip-bolum-title toptitle has-two just-top">
                      <div class="top-title">
                        Müşteri Temsilcileri <br />
                        <span style={{ fontWeight: "300" }}>
                          {" "}
                          ( Consular Transaction Tracking ){" "}
                        </span>{" "}
                      </div>
                    </div>
                    <ul class="ekip-bolum last">
                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Ece UYANIK <span>Customer Representative</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="ekip-department">
                    <div class="ekip-bolum-title toptitle has-three special just-top">
                      <div class="top-title">PROMOTION TEAM</div>
                    </div>
                    <ul class="ekip-bolum last">
                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Selçuk NOHUTCU <span>Promotion Representative</span>
                          </div>
                        </div>
                      </li>
                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Kenan TORAN<span>Promotion Representative</span>
                          </div>
                        </div>
                      </li>
                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Ayşe Sevgi YAZICI
                            <span>Promotion Representative</span>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div class="clearfix"></div>
                  </li>
                  <li class="ekip-department">
                    <div class="ekip-bolum-title toptitle has-three just-top">
                      <div class="top-title">Accounting</div>
                    </div>
                    <ul class="ekip-bolum last">
                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Yalçın LARÇIN <span>Accounting IST</span>
                          </div>
                        </div>
                      </li>

                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            Neil NODEN <span>Accounting NY / USA</span>
                          </div>
                        </div>
                      </li>

                      <li class="ekip-department">
                        <div class="ekip-bolum-title">
                          <div class="name">
                            {" "}
                            Sam DAYEH <span>Accounting FL / USA</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default OrganizationSchema;
