import SplideSliderHome from "../../components/splideSlider";
import React, { useEffect } from "react";
import BlogPost from "../../components/blogPost";
// import Services from "../../components/services";
import SliderHomepage from "../../components/slider";
import Aos from "aos";
import "aos/dist/aos.css";
import "./homepage.scss";
// import DocumentMeta from "react-document-meta";

function Homepage() {
  // const meta = {
  //   title: "TRUSA Investment | Real Estate",
  //   description:
  //     "A young person who went to America in 1996 to get high school education, stays in America for about 15 years after high school, university and business life, and during this time he buys a house in America.",
  //   canonical: "http://example.com/path/to/page",
  //   meta: {
  //     charset: "utf-8",
  //     name: {
  //       keywords:
  //         "TRUSA Investment,TRUSA Investment Management, real estate, consultancy, buying a house in usa, buying a house in america, how to buy house in usa,how to buy house in america, high rental income, rent in dollars",
  //     },
  //   },
  // };
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.addEventListener("scroll", (event) => {
      // console.log(window.pageYOffset);
      Aos.refresh();
    });
  }, []);
  return (
    <>
      <SliderHomepage />
      {/* <Services /> */}
      <SplideSliderHome />
      <BlogPost />
      {/* <DocumentMeta {...meta} /> */}
    </>
  );
}

export default Homepage;
