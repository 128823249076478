import React from "react";

function PrivacyPolicy() {
  return (
    <div
      style={{ marginTop: "50px", marginBottom: "50px" }}
      className="container"
    >
      <strong>PRIVACY AND SECURITY POLICY</strong>
      <br />
      <br />
      Firmamızda verilen tüm servisler ve , Köybaşı Cad. İskele Çıkmazı
      Recaizade Mahmud Ekrem Yalısı No:6 İstinye, Sarıyer / İstanbul adresinde
      kayıtlı TRUSA INVESTMENT MANAGEMENT GAYRİMENKUL HİZMETLERİ TİCARET LİMİTED
      ŞİRKETİ firmamıza aittir ve firmamız tarafından işletilir.
      <br />
      <br />
      Firmamız, çeşitli amaçlarla kişisel veriler toplayabilir. Aşağıda,
      toplanan kişisel verilerin nasıl ve ne şekilde toplandığı, bu verilerin
      nasıl ve ne şekilde korunduğu belirtilmiştir.
      <br />
      <br />
      Firmamızda doldurulan çeşitli form ve anketlerin doldurulması suretiyle
      üyelerin kendileriyle ilgili bir takım kişisel bilgileri (isim-soy isim,
      firma bilgileri, telefon, adres veya e-posta adresleri gibi) firmamız
      tarafından işin doğası gereği toplanmaktadır.
      <br />
      <br />
      Firmamız bazı dönemlerde müşterilerine ve üyelerine kampanya bilgileri,
      yeni ürünler hakkında bilgiler, promosyon teklifleri gönderebilir.
      Üyelerimiz bu gibi bilgileri alıp almama konusunda her türlü seçimi üye
      olurken yapabilir, sonrasında üye girişi yaptıktan sonra hesap bilgileri
      bölümünden bu seçimi değiştirilebilir ya da kendisine gelen bilgilendirme
      iletisindeki linkle bildirim yapabilir.
      <br />
      <br />
      Firmamız üzerinden veya e-posta ile gerçekleştirilen onay sürecinde,
      üyelerimiz tarafından mağazamıza elektronik ortamdan iletilen kişisel
      bilgiler, Üyelerimiz ile yaptığımız sözleşme ile belirlenen amaçlar ve
      kapsam dışında üçüncü kişilere açıklanmayacaktır.
      <br />
      <br />
      Sistemle ilgili sorunların tanımlanması ve verilen hizmet ile ilgili
      çıkabilecek sorunların veya uyuşmazlıkların hızla çözülmesi için,
      Firmamız, üyelerinin IP adresini kaydetmekte ve bunu kullanmaktadır. IP
      adresleri, kullanıcıları genel bir şekilde tanımlamak ve kapsamlı
      demografik bilgi toplamak amacıyla da kullanılabilir.
      <br />
      <br />
      Firmamız, Üyelik Sözleşmesi ile belirlenen amaçlar ve kapsam dışında da,
      talep edilen bilgileri kendisi veya işbirliği içinde olduğu kişiler
      tarafından doğrudan pazarlama yapmak amacıyla kullanabilir. Kişisel
      bilgiler, gerektiğinde kullanıcıyla temas kurmak için de kullanılabilir.
      Firmamız tarafından talep edilen bilgiler veya kullanıcı tarafından
      sağlanan bilgiler veya Mağazamız üzerinden yapılan işlemlerle ilgili
      bilgiler; Firmamız ve işbirliği içinde olduğu kişiler tarafından, "Üyelik
      Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında da, üyelerimizin
      kimliği ifşa edilmeden çeşitli istatistiksel değerlendirmeler, veri tabanı
      oluşturma ve pazar araştırmalarında kullanılabilir.
      <br />
      <br />
      Firmamız, gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır
      saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi,
      gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini
      veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için
      gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt
      etmektedir.
      <br />
      <br />
      İSTİSNAİ HALLER
      <br />
      <br />
      Aşağıda belirtilen sınırlı hallerde Firmamız, işbu "Gizlilik Politikası"
      hükümleri dışında kullanıcılara ait bilgileri üçüncü kişilere
      açıklayabilir. Bu durumlar sınırlı sayıda olmak üzere;
      <br />
      <br />
      1.Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. yetkili hukuki otorite
      tarafından çıkarılan ve yürürlülükte olan hukuk kurallarının getirdiği
      zorunluluklara uymak;
      <br />
      <br />
      2.Mağazamızın kullanıcılarla akdettiği "Üyelik Sözleşmesi"'nin ve diğer
      sözleşmelerin gereklerini yerine getirmek ve bunları uygulamaya koymak
      amacıyla;
      <br />
      <br />
      3.Yetkili idari ve adli otorite tarafından usulüne göre yürütülen bir
      araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla ilgili bilgi
      talep edilmesi;
      <br />
      <br />
      4.Kullanıcıların hakları veya güvenliklerini korumak için bilgi vermenin
      gerekli olduğu hallerdir.
      <br />
      <br />
      E-POSTA GÜVENLİĞİ
      <br />
      <br />
      Firmamız Müşteri Hizmetleri’ne, herhangi bir siparişinizle ilgili olarak
      göndereceğiniz e-postalarda, asla kredi kartı numaranızı veya
      şifrelerinizi yazmayınız. E-postalarda yer alan bilgiler üçüncü şahıslar
      tarafından görülebilir. Firmamız e-postalarınızdan aktarılan bilgilerin
      güvenliğini hiçbir koşulda garanti edemez.
      <br />
      <br />
      TARAYICI ÇEREZLERİ
      <br />
      <br />
      Firmamız, mağazamızı ziyaret eden kullanıcılar ve kullanıcıların web
      sitesini kullanımı hakkındaki bilgileri teknik bir iletişim dosyası
      (Çerez-Cookie) kullanarak elde edebilir. Bahsi geçen teknik iletişim
      dosyaları, ana bellekte saklanmak üzere bir internet sitesinin
      kullanıcının tarayıcısına (browser) gönderdiği küçük metin dosyalarıdır.
      Teknik iletişim dosyası site hakkında durum ve tercihleri saklayarak
      İnternet'in kullanımını kolaylaştırır.
      <br />
      <br />
      Teknik iletişim dosyası, siteyi kaç kişinin ziyaret ettiğini, bir kişinin
      siteyi hangi amaçla, kaç kez ziyaret ettiğini ve ne kadar sitede
      kaldıkları hakkında istatistiksel bilgileri elde etmeye ve kullanıcılar
      için özel tasarlanmış kullanıcı sayfalarından dinamik olarak reklam ve
      içerik üretilmesine yardımcı olur. Teknik iletişim dosyası, ana bellekte
      veya e-postanızdan veri veya başkaca herhangi bir kişisel bilgi almak için
      tasarlanmamıştır. Tarayıcıların pek çoğu başta teknik iletişim dosyasını
      kabul eder biçimde tasarlanmıştır ancak kullanıcılar dilerse teknik
      iletişim dosyasının gelmemesi veya teknik iletişim dosyasının
      gönderildiğinde uyarı verilmesini sağlayacak biçimde ayarları
      değiştirebilirler.
      <br />
      <br />
      Firmamız, işbu "Gizlilik Politikası" hükümlerini dilediği zaman sitede
      yayınlamak veya kullanıcılara elektronik posta göndermek veya sitesinde
      yayınlamak suretiyle değiştirebilir. Gizlilik Politikası hükümleri
      değiştiği takdirde, yayınlandığı tarihte yürürlük kazanır.
      <br />
      <br />
      Gizlilik politikamız ile ilgili her türlü soru ve önerileriniz için
      info@trusa.com.tr adresine email gönderebilirsiniz. Firmamız’a ait
      aşağıdaki iletişim bilgilerinden ulaşabilirsiniz.
      <br />
      <br />
      Firma Ünvanı: TRUSA INVESTMENT MANAGEMENT GAYRİMENKUL HİZMETLERİ TİCARET
      LİMİTED ŞİRKETİ
      <br />
      <br />
      Adres: Köybaşı Cad. İskele Çıkmazı Recaizade Mahmud Ekrem Yalısı No:6
      İstinye, Sarıyer / İstanbul
      <br />
      <br />
      Eposta: info@trusa.com.tr
      <br />
      <br />
      Tel: 0212 212 24 00
    </div>
  );
}

export default PrivacyPolicy;
