import React from "react";
import "./splideSlider.scss";
import { splideSliderPost } from "../../helper/splideSliderPost";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";

function SplideSliderHome() {
  return (
    <div data-aos="fade-right" className="container slideArea">
      <div className="slide-rigth-content">
        <p>LAST SOLD HOMES AND ITS INCOME</p>
        <a className="homepage-portfoy-btn" href="/portfolio">
          <i className="fa fa-arrow-right"></i>PORTFOLIO
        </a>
      </div>
      <Splide
        options={{
          type: "loop",
          perPage: 3,
          perMove: 1,
          arrows: false,
          rewind: true,
          rewindSpeed: 1000,
          autoplay: true,
          pagination: false,
          breakpoints: {
            650: {
              perPage: 1,
            },
          },
        }}
        aria-label="favorite"
      >
        {splideSliderPost.map((item) => {
          return (
            <SplideSlide key={item.id}>
              <div className="splideSlide-cell">
                <img src={item.img} alt="img" />
                <div className="splideSlide-desc">
                  <div className="splideSlide-row">
                    <p>
                      <b>Price</b>
                    </p>
                    <p>
                      <b>${item.price}</b>
                    </p>
                  </div>
                  <div className="splideSlide-row">
                    <p>
                      <b>Rent</b>
                    </p>
                    <p>
                      <b>${item.rent}</b>
                    </p>
                  </div>
                  <div className="splideSlide-row">
                    <p>
                      <b>Tenant Guaranteed Net Rent</b>
                    </p>
                    <p>
                      <b>${item.rentGuaranteedNetRent}</b>
                    </p>
                  </div>
                  <div className="splideSlide-row">
                    <p>
                      <b>Depreciation Time</b>
                    </p>
                    <p>
                      <b>${item.depreciationTime}</b>
                    </p>
                  </div>
                </div>
              </div>
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
}

export default SplideSliderHome;
