/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./footer.scss";
import { useNavigate } from "react-router-dom";

function Footer() {
  let navigate = useNavigate();
  return (
    <footer>
      <div className="container">
        <div className="footer-content">
          <div className="footer-menu-div">
            <span className="footer-menu-title">MENU</span>
            <div className="footer-menu-item">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Homepage
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/certificates");
                    }}
                  >
                    Certificates
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/advantages");
                    }}
                  >
                    Advantages
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/academy");
                    }}
                  >
                    Academy
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/buying-a-house-in-america");
                    }}
                  >
                    Buying a House in America
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/flip");
                    }}
                  >
                    Flip
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/communication");
                    }}
                  >
                    Communication
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/corporate");
                    }}
                  >
                    Corporate
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/we-in-the-press");
                    }}
                  >
                    We in The Press
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/portfolio");
                    }}
                  >
                    Portfolio
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    Business Line
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/gallery");
                    }}
                  >
                    Gallery
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/blog");
                    }}
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </div>
            <span className="footer-menu-title">POLICIES</span>
            <div className="footer-menu-item">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    KVKK Aydınlatma Metni
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/cookie-policy");
                    }}
                  >
                    Cookie Policy
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/privacy-policy");
                    }}
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-menu-div">
            <span className="footer-menu-title">BUSINESS LINE</span>
            <div className="footer-menu-item">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    Starbucks Coffee
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    Country Czech Bakery
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    Chasebank
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    Wells Fargo
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    Applebee's
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    Corporate Arby's
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    Burger King
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    Shell Station
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-menu-div">
            <span className="footer-menu-title">Information Catalog</span>
            <div className="footer-menu-item">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/catalogs");
                    }}
                  >
                    Catalogs
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-menu-div">
            <span className="footer-menu-title">Communication</span>
            <div className="footer-menu-item">
              <ul>
                <li>
                  <b>TRUSA Investment Management</b>
                </li>
                <li>
                  <i>
                    Manarat Tower, Building no 23, Zone 69 Street 318, 25th
                    Floor, Lusail, Qatar
                  </i>
                  <br />
                  <ul>
                    <li>
                      <a
                        className="footer-phone-anchor"
                        href="tel:+97433030256"
                      >
                        <i>+974 3303 0256</i>
                      </a>
                    </li>
                  </ul>
                </li>
                <br />
                <br />
                <li>
                  <i>
                    Köybaşı Cad. İskele Çıkmazı Recaizade Mahmud Ekrem Yalısı
                    No:6
                  </i>
                  <br />
                  <ul>
                    <li>
                      <a
                        className="footer-phone-anchor"
                        href="tel:0212 212 24 00"
                      >
                        <i>0212 212 24 00 </i>
                      </a>
                      |
                      <a
                        className="footer-phone-anchor"
                        href="tel:0532 399 2400"
                      >
                        <i> 0532 399 2400</i>
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <i>İstinye, Sarıyer İstanbul</i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
