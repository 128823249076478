/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./header.scss";
import logo from "../../images/logo-trusa.png";
import phoneIcon from "../../images/phone-icon.png";

function Header() {
  let navigate = useNavigate();
  useEffect(() => {
    document.getElementsByClassName("bm-burger-button")[0].style.top = `${
      document.getElementById("top-header").offsetHeight + 36
    }px`;
    window.addEventListener("scroll", (event) => {
      if (
        window.pageYOffset < document.getElementById("top-header").offsetHeight
      ) {
        document.getElementsByClassName("bm-burger-button")[0].style.top = `${
          36 +
          document.getElementById("top-header").offsetHeight -
          window.pageYOffset
        }px`;
      }
    });
  }, []);
  return (
    <>
      <div id="top-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="home-account">
                <li className="dot">
                  <img style={{ width: "10px" }} src={phoneIcon} alt="phone" />
                  <a style={{ fontSize: "0.9em" }} href="tel:+97433030256">
                    <b>+974 3303 0256</b>
                  </a>
                </li>
                <li className="dot">
                  <button
                    onClick={() => {
                      navigate("/buying-a-house-in-america");
                    }}
                  >
                    F.A.Q.
                  </button>
                </li>
                <li className="dot">
                  <button
                    onClick={() => {
                      navigate("/blog");
                    }}
                  >
                    Blog
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      navigate("/catalogs");
                    }}
                  >
                    Catalogs
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <header>
        <div id="main-header">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="logo">
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <img src={logo} title="Trusa" alt="Trusa" />
                  </a>
                </div>
              </div>
              <div className="col-md-9">
                <div className="main-menu">
                  <ul>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li
                      className="corporate-header-button"
                      style={{ position: "relative" }}
                    >
                      <a
                        onClick={() => {
                          navigate("/corporate");
                        }}
                      >
                        Corporate
                      </a>
                      <div className="corporate-absolute-div">
                        <div className="corporate-absolute-area">
                          <div className="divider-triangle"></div>
                          <ul>
                            <li
                              onClick={() => {
                                navigate("/corporate");
                              }}
                            >
                              <a>Trusa Investment</a>
                            </li>
                            <li
                              onClick={() => {
                                navigate("/organization-schema");
                              }}
                            >
                              <a>Organization Schemas</a>
                            </li>
                            <li
                              onClick={() => {
                                navigate("/certificates");
                              }}
                            >
                              <a>Certificates</a>
                            </li>
                            <li
                              onClick={() => {
                                navigate("/we-in-the-press");
                              }}
                            >
                              <a>We in The Press</a>
                            </li>
                            <li
                              onClick={() => {
                                navigate("/catalogs");
                              }}
                            >
                              <a>Catalogs</a>
                            </li>
                            <li
                              onClick={() => {
                                navigate("/gallery");
                              }}
                            >
                              <a>Gallery</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/advantages");
                        }}
                      >
                        Advantages
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/portfolio");
                        }}
                      >
                        Portfolio
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/business");
                        }}
                      >
                        Business Line
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/flip");
                        }}
                      >
                        Flip
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/academy");
                        }}
                      >
                        Academy
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigate("/communication");
                        }}
                      >
                        Communication
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
