import React from "react";
import "./academy.scss";
import trusaAcademy from "../../images/trusa-academy.jpg";

function Academy() {
  return (
    <div className="container academy-div">
      <img src={trusaAcademy} alt="trusa academy" />
      <h1>We Invest in Education with Trusa Akademi.</h1>
      <p>
        Trusa, which is with you in your real estate investments, is also with
        the young people with its education scholarship. Trusa Academy provides
        education scholarships to our promising young people in the USA. Upon
        graduation, it offers the opportunity to work in one of Trusa's offices
        in five US states. As a well-established brand, we support our
        successful students at every step and invest in a bright future.
      </p>
    </div>
  );
}

export default Academy;
