import React from "react";
import "./katalog.scss";
import imgTR from "../../images/pdf/katalog-tr-23817.jpg";
import imgEN from "../../images/pdf/katalog-en-50317.jpg";
import imgAE from "../../images/pdf/katalog-ae-50600.jpg";
import pdfTR from "../../images/pdf/trusa-katalog-opt.pdf";
import pdfEN from "../../images/pdf/trusa-ingilizce-opt.pdf";
import pdfAE from "../../images/pdf/trusa-katalog-arapca-opt.pdf";

function Katalog() {
  return (
    <div className="container katalog-content">
      <h3>Catalogs</h3>
      <div className="katalog-card-div">
        <a
          href={pdfTR}
          target="_blank"
          className="katalog-grid-cell"
          rel="noreferrer"
        >
          <img src={imgTR} alt="pdf img TR" />
          <div className="katalog-title">Türkçe</div>
        </a>
        <a
          href={pdfEN}
          target="_blank"
          className="katalog-grid-cell"
          rel="noreferrer"
        >
          <img src={imgEN} alt="pdf img TR" />
          <div className="katalog-title">English</div>
        </a>
        <a
          href={pdfAE}
          target="_blank"
          className="katalog-grid-cell"
          rel="noreferrer"
        >
          <img src={imgAE} alt="pdf img TR" />
          <div className="katalog-title">فهرس</div>
        </a>
      </div>
    </div>
  );
}

export default Katalog;
