import React from "react";

function CookiePolicy() {
  return (
    <div style={{ marginTop: "50px", marginBottom: "50px" }} class="container">
      <p>
        <strong>Çerez Politikası Örneği</strong>
      </p>

      <p>
        <strong>Çerez nedir?</strong>
      </p>

      <p>
        Ziyaret ettiğiniz web siteleri, bilgisayarınıza veya telefonunuza küçük
        bilgiler kaydedip, daha sonra aynı siteyi ziyaret ettiğinizde bu
        bilgileri okuyabilirler. Bu küçük bilgilerin kaydedildiği dosyalara
        çerez veya tanımlama bilgisi (İngilizcesi: cookie) denir. Çerezler
        genellikle internette gezinti deneyiminizi iyileştirmeye ve siteleri
        kişiselleştirmeye yarar. Örneğin bir siteye giriş yaparken “beni
        hatırla” seçeneğini işaretlerseniz o site, kullanıcı adınızı (veya özel
        bir kimlik numarasını) içeren bir çerezi tarayıcınıza kaydeder.
        Çerezleri yalnızca onları oluşturan site okuyabilir. Çerezin ne kadar
        süreyle saklanacağını da site belirler.
      </p>

      <p>
        <strong>Çerez Kullanımı</strong>
      </p>

      <p>
        <strong>ÇEREZ (“COOKIE”)</strong>
      </p>

      <p>
        İnternet sitemizden (trusa.com.tr) en verimli şekilde faydalanabilmeniz
        ve kullanıcı deneyiminizi geliştirebilmek için Cookie kullanıyoruz. Web
        sitemize giriş yaptığınız zaman, sizlere, Cookie kullanımı kabul edip
        etmediğinize dair bir soru sorulmaktadır. <em>'Kabul Et'</em> seçeneğini
        seçtikten sonra Cookie'ler aktif olacaktır. Sonradan fikrinizi
        değiştirirseniz ve Cookie kullanılmasını tercih etmezseniz tarayıcınızın
        ayarlarından Cookie’leri her zaman silebilir ya da engelleyebilirsiniz.
        Ancak bunun internet sitemizi kullanımınızı etkileyebileceğini
        hatırlatmak isteriz. Tarayıcınızdan Cookie ayarlarınızı
        değiştirmediğiniz sürece bu sitede Cookie'ler kullanılmaya devam
        edecektir. Cookie'lerin hangi amaçla kullanıldığı ve ne kadar süre
        depolandığı aşağıda belirtilmektedir. Sitemizde yer alan servislerin
        kullanımı için bizimle doğrudan veya dolaylı olarak bazı bilgi ve
        veriler paylaşmaktasınız. Bu bilgiler siteye kayıt olurken verdiğiniz
        kişisel bilgiler (<em>isim, e-posta adresi, telefon gibi</em>) veya
        siteyi kullanımınız (
        <em>iletişim formunu kullanırken, yorum yaparken vb</em>.) esnasında
        topladığımız bilgilerdir (
        <em>
          IP, cihaz, konum ve tarayıcı bilgileri, gezinti ve etkileşim içeriği,
          tarih ve bağlantı süresi gibi veriler
        </em>
        ). Bilgi ve verilerinizi saklama konusunda azami özeni göstermekteyiz.
        Web sitemiz, sunucumuzda depolanan Cookie verilerinizi 3. parti kişiler
        ile paylaşılmamaktadır. Sitede bulunan harici hizmetler (
        <em>
          Canlı Destek eklentisi, Facebook eklentisi, YouTube videosu, Twitter
          içeriği vb
        </em>
        .) tarafından depolanan Cookie verileriniz ise harici hizmetlerin
        sorumluluğundadır. Bunun için; '
        <a href="/kvkk-aydinlatma-metni" title="KVKK">
          KVKK
        </a>
        ' ve '
        <a href="/privacy-policy" title="Gizlilik Politikası">
          Gizlilik Politikası
        </a>
        ' sayfalarımıza göz atınız.
      </p>

      <p>
        <strong>İNTERNET SİTESİNDE KULLANILAN ÇEREZLER</strong>
      </p>

      <p>
        <strong>Reşit Olmayanlar</strong>&nbsp;
      </p>

      <p>
        18 yaşın altındaysanız Web Sitesi'ni ebeveynlerinizin refakati altında
        ziyaret etmeniz gerekmektedir. Ebeveynlerinizin refakati olmadan bu Web
        Sitesi'ni kullanmamanız ve hiçbir kişisel verinizi paylaşmamanız
        gerekmektedir.
      </p>

      <p>
        <strong>Çerez Nedir ve Neden Kullanılmaktadır?</strong>
      </p>

      <p>
        Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar
        aracılığıyla cihazınıza veya ağ sunucusuna depolanan küçük metin (veri)
        dosyalarıdır.
      </p>

      <ul>
        <li>
          İnternet Sitesinin işlevselliğini ve performansını arttırmak yoluyla
          sizlere sunulan hizmetleri geliştirmek,
        </li>
        <li>
          İnternet Sitesini iyileştirmek, İnternet Sitesi üzerinden yeni
          özellikler sunmak ve sunulan özellikleri sizlerin tercihlerine göre
          kişiselleştirmek,
        </li>
        <li>
          İnternet Sitesinin, sizin ve şirketimizin hukuki ve ticari
          güvenliğinin teminini sağlamak.
        </li>
      </ul>

      <p>
        <strong>Çerez Türleri</strong>
      </p>

      <p>
        Ana kullanım amacı kullanıcılara kolaylık sağlamak olan çerezler, temel
        olarak 4 ana grupta toplanmaktadır:
      </p>

      <ul>
        <li>
          <strong>Oturum Çerezleri:</strong>&nbsp;İnternet sayfaları arasında
          bilgi taşınması ve kullanıcı tarafından girilen bilgilerin sistemsel
          olarak hatırlanması gibi çeşitli özelliklerden faydalanmaya olanak
          sağlayan çerezlerdir ve internet sitesine ait fonksiyonların düzgün
          bir şekilde işleyebilmesi için gereklidir.
        </li>
        <li>
          <strong>Performans Çerezleri:</strong>&nbsp;Sayfaların ziyaret edilme
          frekansı, olası hata iletileri, kullanıcıların ilgili sayfada
          harcadıkları toplam zaman ile birlikte siteyi kullanım şekilleri
          konularında bilgi toplayan çerezlerdir ve internet sitesinin
          performansını arttırma amacıyla kullanılmaktadır.
        </li>
        <li>
          <strong>Fonksiyonel Çerezler:</strong>&nbsp;Kullanıcıya kolaylık
          sağlanması amacıyla önceden seçili olan seçeneklerin hatırlatılmasını
          sağlayan çerezlerdir ve internet sitesi kapsamında kullanıcılara
          gelişmiş internet özellikleri sağlanmasını hedeflemektedir.
        </li>
        <li>
          <strong>Reklam ve Üçüncü Taraf Çerezleri:</strong>&nbsp;Üçüncü parti
          tedarikçilere ait çerezlerdir ve internet sitesindeki bazı
          fonksiyonların kullanımına ve reklam takibinin yapılmasına (uygun
          reklam gösterilmesine) olanak sağlamaktadır.
        </li>
      </ul>

      <p>
        <strong>Çerezlerin Kullanım Amaçları</strong>
      </p>

      <p>
        (trusa.com.tr) tarafından kullanılmakta olan çerezlere ait kullanım
        amaçları aşağıdaki gibidir:
      </p>

      <ul>
        <li>
          <strong>Güvenlik amaçlı kullanımlar:</strong> ornekalanadi.com,
          sistemlerinin idaresi ve güvenliğinin sağlanması amacıyla, bu sitedeki
          fonksiyonlardan yararlanmayı sağlayan veyahut düzensiz davranışları
          tespit eden çerezler kullanabilmektedir.
        </li>
        <li>
          <strong>İşlevselliğe yönelik kullanımlar:</strong> ornekalanadi.com,
          sistemlerinin kullanımını kolaylaştırmak ve kullanıcı özelinde
          kullanım özellikleri sağlamak amacıyla, kullanıcıların bilgilerini ve
          geçmiş seçimlerini hatırlatan çerezler kullanabilmektedir.
        </li>
        <li>
          <strong>Performansa yönelik kullanımlar:</strong> ornekalanadi.com,
          sistemlerinin performansının artırılması ve ölçülmesi amacıyla,
          gönderilen iletilerle olan etkileşimi ve kullanıcı davranışlarını
          değerlendiren ve analiz eden çerezler kullanabilmektedir.
        </li>
        <li>
          <strong>Reklam amaçlı kullanımlar:</strong> ornekalanadi.com, kendine
          veya üçüncü taraflara ait sistemlerin üzerinden kullanıcıların ilgi
          alanları kapsamında reklam ve benzeri içeriklerin iletilmesi amacıyla,
          bu reklamların etkinliğini ölçen veya tıklanma durumunu analiz eden
          çerezler kullanabilmektedir.
        </li>
      </ul>

      <p>
        <strong>Çerezleri Kontrol Etme ve Silme</strong>
      </p>

      <p>
        Çerezlerin kullanımına ilişkin tercihlerinizi değiştirmek ya da
        çerezleri engellemek veya silmek için tarayıcınızın ayarlarını
        değiştirmeniz yeterlidir. Birçok tarayıcı çerezleri kontrol edebilmeniz
        için size çerezleri kabul etme veya reddetme, yalnızca belirli türdeki
        çerezleri kabul etme ya da bir web sitesi cihazınıza çerez depolamayı
        talep ettiğinde tarayıcı tarafından uyarılma seçeneği sunar. Aynı
        zamanda daha önce tarayıcınıza kaydedilmiş çerezlerin silinmesi de
        mümkündür. Çerezleri kontrol edilmesine veya silinmesine ilişkin
        işlemler kullandığınız tarayıcıya göre değişebilmektedir. Bazı popüler
        tarayıcıların çerezlere izin verme ya da çerezleri engelleme veya silme
        talimatlarına aşağıda gösterilmiştir.
      </p>

      <p>
        Çerez kullanım seçiminin değiştirilmesine ait yöntem, tarayıcı tipine
        bağlı olarak değişmekte olup, ilgili hizmet sağlayıcıdan dilendiği zaman
        öğrenilebilmektedir.
      </p>

      <p>
        <strong>Çerezlerin Kullanılmasını Nasıl Engelleyebilirsiniz?</strong>
        &nbsp;Çerezlerin kullanılması Web Sitesi'nin daha iyi hizmet vermekle
        birlikte eğer dilerseniz çerezlerin kullanılmasını engelleyebilirsiniz.
        Ancak bu takdirde sitenin tam olarak işlevini gösterememesi ve tüm
        özelliklerinden yararlanamayabileceğinizi unutmayınız. Çerezlerin
        kullanılmasını engellemek için kullandığınız internet tarayıcınızın
        ayarlarınızı değiştirmeniz gerekmektedir. Bu değişiklikler kullandığınız
        cihaz ve internet tarayıcısına göre değişiklik göstermektedir. Aşağıda
        farklı internet tarayıcıları üzerinden çerezlerin kullanılmasını
        engellemek için hangi adımların izlenmesi gerektiğine ilişkin bilgiler
        yer almaktadır:
        <br />
        <br />
        •&nbsp;<strong>Internet Explorer</strong>
      </p>

      <ol>
        <li>
          Masaüstünü açın ve görev çubuğunda Internet Explorer simgesine dokunun
          veya tıklayın.
        </li>
        <li>
          Araçlar düğmesine ve İnternet seçenekleri'ne dokunun veya tıklayın.
        </li>
        <li>
          Gizlilik sekmesine dokunun veya tıklayın, ardından tüm tanımlama
          bilgilerini engellemek için Ayarlar'ın altında bulunan kaydırıcıyı
          yukarıya hareket ettirin ve Tamam düğmesine dokunun veya tıklayın.
        </li>
      </ol>

      <p>
        •&nbsp;<strong>Microsoft Edge</strong>
      </p>

      <ol>
        <li>
          Microsoft Edge tarayıcınızın sağ üst köşesinden üç nokta işareti olan
          bölüme tıklayın ve Ayarlar bölümüne gelin.
        </li>
        <li>
          Karşınıza gelen yeni pencereden Temizlenecek Öğeleri Seç dedikten
          sonra karşınıza gelen pencereden temizlemek istediğiniz bölümleri
          seçin.
        </li>
        <li>
          Burada birçok bölüm bulunmaktadır. İstediğinizi seçerek temizleme
          işlemine başlayabilirsiniz
        </li>
      </ol>

      <p>
        •&nbsp;<strong>Google Chrome</strong>
      </p>

      <ol>
        <li>Bilgisayarınızda Chrome'u açın.</li>
        <li>Sağ üstte Diğer Ayarlar'ı tıklayın.</li>
        <li>Altta Gelişmiş'i tıklayın.</li>
        <li>"Gizlilik ve güvenlik"in altında İçerik ayarları'nı tıklayın.</li>
        <li>Çerezler'i tıklayın.</li>
        <li>
          "Tüm çerezler ve site verileri"nin altında Web Sitesi'nin adını
          arayın.
        </li>
        <li>Sitenin sağındaki Kaldır simgesini tıklayın</li>
      </ol>

      <p>
        •&nbsp;<strong>Mozilla Firefox</strong>
      </p>

      <ol>
        <li>Firefox Menü düğmesine tıklayın ve Seçenekler'i seçin.</li>
        <li>Gizlilik ve Güvenlik panelini seçin ve Geçmiş bölümüne gidin.</li>
        <li>
          Firefox ayarını geçmiş için özel ayarları kullansın olarak değiştirin.
        </li>
        <li>
          Çerezleri göster... düğmesine tıklayın. Çerezler penceresi
          görünecektir.
        </li>
        <li>
          Arama: alanına, çerezlerini silmek istediğiniz sitenin adını yazın.
          Aramanızla eşleşen çerezler görüntülenecektir.
        </li>
        <li>
          Silmek istediğiniz çerez(ler)i seçip Seçilenleri sil düğmesine
          tıklayın.
        </li>
        <li>
          Kapat düğmesine tıklayarak Çerezler penceresini kapatın. Sonra da
          about:preferences sayfasını kapatın
        </li>
      </ol>

      <p>
        •&nbsp;<strong>Safari</strong>
      </p>

      <ol>
        <li>Safari &gt; Tercihler'i seçin.</li>
        <li>Gizlilik öğesini tıklayın.</li>
        <li>Web Sitesi Verilerini tıklayın.</li>
        <li>
          Bir veya daha fazla web sitesi seçin ve sonra Sil veya Tümünü Sil'e
          tıklayın. Ziyaret ettiğiniz web sitelerinin geride bıraktığı tüm
          çerezli üçüncü parti yazılımlar aracılığıyla da silebilirsiniz.
        </li>
      </ol>

      <p>
        <strong>
          Bu sitede Google Analytics Ziyaretçi Takip Sistemi Kullanılmaktadır
        </strong>
      </p>

      <p>
        <strong>
          <u>Sitede Kullanılan Çerezler:</u>
        </strong>
        &nbsp;‘Çerez Politikası’nda, sitede kullanılan çerezler tek tek
        listelenmeli ve hepsinin ne işe yaradığı tek tek açıklamaları ile
        yazılmalıdır. Ayrıca hangi çerez ne kadar süre ile saklanıyor, ne
        özelliği var vb. bunlar tek tek listelenmelidir.
      </p>

      <p>
        <strong>SAKLANDIĞI SÜRE BAKIMINDAN ÇEREZ TÜRLERİ:</strong>
      </p>

      <p>
        <strong>Kalıcı Çerezler (Persistent Cookies):</strong>&nbsp;Kişinin
        bilgisayarında belirli bir tarihe veya kullanıcı tarafından silinene
        kadar varlığını sürdüren çerezlerdir. Bu çerezler, çoğunlukla
        kullanıcıların site hareketlerini ve tercihlerini ölçmek amacıyla
        kullanılır.
      </p>

      <p>
        <strong>Oturum Çerezleri (Session Cookies):</strong>&nbsp;Bu çerezler
        kullanıcının ziyaretini oturumlara ayırmak için kullanılır ve
        kullanıcıdan veri toplamaz. Çerez, kullanıcı ziyaret ettiği web
        sayfasını kapattığında veya belli bir süre pasif kaldığında silinir. Web
        Sitesi'nde üçüncü kişilerin başta reklam hizmetleri olmak üzere hizmet
        sağlamasına yardımcı olmak ve bu hizmetlerin etkinliğini artırmak için
        hedef ve izleme çerezleri kullanılır. Bu çerezler, ziyaret ettiğiniz web
        sayfalarını ve siteleri hatırlayabilir ve başta kullanıcı cihazının IP
        adresi olmak üzere kişisel verileri toplayabilir. Web Sitesi, bilgi
        toplamak, ilgi alanlarınızı ve demografik verileri hatırlamak ve size
        hedeflenmiş reklamları sunmak, reklamları iyileştirmek ziyaret edilme ve
        reklam gösterimleri sayısını, reklam hizmetlerinin diğer kullanımlarını
        ve bu reklam gösterimleri ve reklam hizmetleriyle ilgili etkileşimler
        arasındaki oranı belirlemek için hem birinci taraf hem de üçüncü taraf
        çerezlerini kullanmaktadır. Web Sitesi; Facebook ve LinkedIn gibi sosyal
        ağlara bağlantı sağlayan sosyal eklentilerden faydalanmaktadır. Web
        Sitesi'ni ziyaret ettiğinizde ve bu eklentileri kullandığınızda, Web
        Sitesi doğrudan seçilen sosyal ağın sunucusuna bağlanır. Ardından,
        eklentinin sunduğu içerik doğrudan sosyal ağlardan web tarayıcınıza
        iletilir ve ziyaret etmekte olduğunuz web sitesine eklenir. Böylelikle
        ilgili sosyal ağ size ait verilere ulaşarak işleyebilir ve ilgili sosyal
        ağdaki hesabınıza ait verilerle birleştirebilir.
      </p>

      <p>
        Lütfen sosyal ağların eklentiler aracılığıyla işlediği verilerin kapsamı
        üzerinde herhangi bir etkimiz ve kontrolümüz olmadığını unutmayın.
        Sosyal ağların kişisel verilerinizi hangi amaçla, hangi yöntemlerle ve
        hangi süreyle işleyeceğine ilişkin daha fazla bilgi almak için lütfen
        ilgili sosyal ağlar tarafından yayınlanan kişisel verilerin işlenmesi
        politikalarını dikkatlice inceleyin.
      </p>

      <table>
        <tbody>
          <tr>
            <td width="117">
              <p>
                <strong>Çerezin Adı</strong>
              </p>
            </td>
            <td width="400">
              <p>
                <strong>Kullanım Amacı</strong>
              </p>
            </td>
            <td width="117">
              <p>
                <strong>Kullanım Süresi</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="117">
              <p>Google Analytics</p>
            </td>
            <td width="400">
              <p>
                Google Analytics, çerezleri ziyaretçilerin Web Sitesini nasıl
                kullandıklarını analiz etmek amacıyla kullanır. Bu Web Sitesini
                kullanımınızla ilgili bilgiler (IP adresiniz dahil) Google'a
                aktarılarak Google tarafından ABD'deki sunucularda
                saklanmaktadır. Google bu bilgileri Web Sitesini kullanımınızı
                değerlendirmek, Şirket için Web Sitesi faaliyetini derlemek ve
                Web Sitesi faaliyeti ve internet kullanımıyla ilgili başka
                hizmetler sağlamak amacıyla kullanacaktır, fakat IP adresinizi
                Google tarafından depolanan diğer verilerle eşleştirmeyecektir.
                Google Analytics kullanımı hakkında daha fazla bilgi için
                (reddetme seçenekleri dahil), şu adresi ziyaret
                edebilirsiniz:&nbsp;
                <a
                  rel="noreferrer"
                  href="http://www.google.co.uk/intl/en/analytics/privacyoverview.html"
                  target="_blank"
                  title="Google Privacy"
                >
                  Google Privacy
                </a>
                .
              </p>
            </td>
            <td width="117">
              <p>
                Kalıcı Çerezler
                <br />
                Oturum Çerezleri
              </p>
            </td>
          </tr>
          <tr>
            <td width="117">
              <p>Google Ads</p>
            </td>
            <td width="400">
              <p>
                Google Ads, çerezleri, güvenli arama tercihlerinizi hatırlamak,
                size daha alakalı reklamlar göstermek, bir sayfaya kaç ziyaretçi
                geldiğini belirlemek, hizmetlerimize kaydolmanıza yardımcı
                olmak, verilerinizi korumak veya reklam ayarlarınızı hatırlamak
                için kullanır. Bu, web sitesinin ziyaretinizle ilgili bilgileri
                (örneğin, tercih ettiğiniz dil ve diğer ayarlar) hatırlamasına
                yardımcı olur. Bu Web Sitesini kullanımınızla ilgili bilgiler
                (IP adresiniz dahil) Google'a aktarılarak Google tarafından
                ABD'deki sunucularda saklanmaktadır. Google bu bilgileri Web
                Sitesini kullanımınızı değerlendirmek, Şirket için Web Sitesi
                faaliyetini derlemek ve Web Sitesi faaliyeti ve internet
                kullanımıyla ilgili başka hizmetler sağlamak amacıyla
                kullanacaktır, fakat IP adresinizi Google tarafından depolanan
                diğer verilerle eşleştirmeyecektir. Google Ads çerez kullanımı
                hakkında daha fazla bilgi için (reddetme seçenekleri dahil), şu
                adresi ziyaret edebilirsiniz:&nbsp;
                <a
                  rel="noreferrer"
                  href="https://policies.google.com/privacy?hl=tr"
                  target="_blank"
                  title="Google Privacy"
                >
                  Google Privacy
                </a>
              </p>
            </td>
            <td width="117">
              <p>
                Kalıcı Çerezler
                <br />
                Oturum Çerezleri
              </p>
            </td>
          </tr>
          <tr>
            <td width="117">
              <p>RMC</p>
            </td>
            <td width="400">
              <p>
                RMC, web tarayıcısı aracılığıyla bilgisayarınıza veya üçüncü
                taraf bir analiz sağlayıcısına ait benzersiz bir tanımlayıcı
                içeren küçük metin dosyaları olan “çerezleri” kullanır. Bunu
                hizmetleri ve web sitelerini geliştirmek için kullanır. RMC,
                belirli pazarlama kampanyalarının ve bazı ürünlerinin
                performansını izlemek için Google Analytics'i kullanır. Bu,
                üçüncü taraf hizmet sağlayıcıların, farklı temas noktalarında
                RMC adına uyarlanmış reklamlar oluşturmasına izin vermektir. Bu
                servis sağlayıcılar, kişisel bilgilerinizi, iletişim
                bilgilerinizi vb. İçermeyen IP adresinizi ve kişisel olarak
                tanımlanamayan bilgilerinizi toplayabilir. Bu çerezler ayrıca
                web sitemizin nasıl kullanıldığına ve performansına ilişkin
                istatistiksel bilgiler de sağlar. RMC çerez kullanımı hakkında
                daha fazla bilgi için (reddetme seçenekleri dahil), şu adresi
                ziyaret edebilirsiniz:&nbsp;
                <a
                  rel="noreferrer"
                  href="https://www.relateddigital.com/en/rmc-product-privacy-policy"
                  target="_blank"
                  title="Related Digital Privacy"
                >
                  Related Digital Privacy
                </a>
              </p>
            </td>
            <td width="117">
              <p>
                Kalıcı Çerezler
                <br />
                Oturum Çerezleri
              </p>
            </td>
          </tr>
          <tr>
            <td width="117">
              <p>Facebook</p>
            </td>
            <td width="400">
              <p>
                Facebook çerezleri, facebook hesabınız varsa, site ve
                uygulamalar dahil olmak üzere Facebook ürünlerini kullanırsanız
                veya Facebook ürünlerini kullanan siteleri veya uygulamaları
                (Beğen düğmesi ve diğer Facebook Teknolojileri dahil) ziyaret
                ederseniz, kullanır. Çerezler, Facebook'un Facebook ürünlerini
                size sunmasını ve kayıtlı veya giriş yapmış olup olmamanızdan
                bağımsız olarak, diğer internet siteleri ve uygulamaları
                kullanımınız dahil olmak üzere sizin hakkında aldığı bilgileri
                anlamasını sağlar. Çerezleri; kimlik kontrolü, güvenlik ve site
                bütünlüğü, reklamlar, tavsiyeler, ölçüm, analiz ve raporlama
                için kullanır.
              </p>

              <p>
                Ayrıca ilginizi çekebilecek reklamları sosyal medya
                platformlarında da sunabilmek ve özel hedef kitle oluşturmak
                amacıyla kişisel verilerinizden e-posta adresiniz bu platformlar
                ile paylaşılmaktadır. E-posta adresiniz, bu platformların
                sunduğugüvenli kanallar ve ortamlar üzerinden aktarılmaktadır.
                Sosyal medya platformları, e-posta adresinizi hashleyerek,
                yalnızca eşleştirme işlemi için kullanmaktadır. E-Posta
                adresiniz üçüncü taraflarla veya diğer reklam verenlerle
                paylaşılmaz ve eşleştirme işlemi tamamlandıktan sonra mümkün
                olan en kısa sürede sosyal medya platformlarının sistemlerinden
                silinir. Örneğin; Facebook, (a) kişisel veriniz Facebook
                sistemlerinde bulunduğu sürece verilerin güvenliğini ve
                bütünlüğünü korumak ve (b) Facebook sistemlerinde bulunan
                kişisel verinize yanlışlıkla veya yetkisiz olarak erişilmesine
                ve verinizin yanlışlıkla veya yetkisiz olarak kullanılmasına,
                değiştirilmesine veya ifşa edilmesine karşı korumak için
                geliştirilen teknik ve fiziksel güvenlik önlemlerini de içerecek
                şekilde, özel hedef kitlenizi ("özel hedef kitleniz") oluşturan
                hash yöntemiyle şifrelenen e-posta adresinizin ve Facebook
                Kullanıcı Kimliği koleksiyonunun gizliliğini ve güvenliğini
                sağlayacaktır. Ayrıca, izniniz olmadan veya yasalar
                gerektirmediği sürece, Facebook üçüncü taraflara veya diğer
                reklam verenlere özel hedef kitleniz için erişim veya bilgi
                vermez, özel hedef kitle bilgilerinizi kullanıcılarımız
                hakkındaki bilgilere eklemez veya ilgi alanına dayalı profiller
                oluşturmaz ya da özel hedef kitlenizi size hizmet sunmanın
                haricinde kullanmaz. Facebook özel hedef kitleler koşulları
                için&nbsp;
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/ads/manage/customaudiences/tos.php?_=_"
                  target="_blank"
                  title="Facebook CustomAudiences"
                >
                  Facebook CustomAudiences
                </a>
                &nbsp;adresini, Facebook Gizlilik İlkeleri için&nbsp;
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/privacy/explanation"
                  target="_blank"
                  title="Facebook Privacy"
                >
                  Facebook Privacy
                </a>
                &nbsp;adresini ziyaret edebilirsiniz.
              </p>
            </td>
            <td width="117">
              <p>
                Kalıcı Çerezler
                <br />
                Oturum Çerezleri
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        <br />
        &nbsp;
      </p>

      <p>
        (trusa.com.tr) Sitemizde Kullanılan Çerezlere ilişkin bilgiler aşağıdaki
        tabloda yer almaktadır:
      </p>

      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>Servis Sağlayıcı</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Çerez İsmi</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Çerez Amacı</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Çerez Tipi</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Çerez Süresi</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_BirthDate</p>
            </td>
            <td>
              <p>Kullanıcının doğum tarihi</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_CityName</p>
            </td>
            <td>
              <p>Kullanıcının yaşadığı şehir</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_Education</p>
            </td>
            <td>
              <p>Kullanıcının üniversitesi ve bölümü</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_EducationLevel</p>
            </td>
            <td>
              <p>Kullanıcının eğitim durumu</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_Gender</p>
            </td>
            <td>
              <p>Kullanıcının cinsiyeti</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_Geo</p>
            </td>
            <td>
              <p>Kullanıcının yaşadığı lokasyon</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_LastJobTitle</p>
            </td>
            <td>
              <p>Kullanıcının son pozisyonunun adı</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_LastJobTitleSegmentCode</p>
            </td>
            <td>
              <p>Kullanıcının müşteri segmenti</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_Login</p>
            </td>
            <td>
              <p>Kullanıcının giriş yapıp yapmadığı bilgisi</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_Login_Module</p>
            </td>
            <td>
              <p>Kullanıcının neyin üzerinden giriş yaptığı bilgisi</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_Ozgecmis</p>
            </td>
            <td>
              <p>
                Kullanıcının özgeçmişinin herkese açık olup olmadığı bilgisi
              </p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_UpdateResume</p>
            </td>
            <td>
              <p>Kullanıcının özgeçmişinin güncel olup olmadığı bilgisi</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_WorkExperience</p>
            </td>
            <td>
              <p>Kullanıcının ne kadar iş tecrübesi olduğu bilgisi</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google</p>
            </td>
            <td>
              <p>CV_WorkStatus</p>
            </td>
            <td>
              <p>Kullanıcının iş sahipliği bilgisi</p>
            </td>
            <td>
              <p>Persistent Cookie</p>
            </td>
            <td>
              <p>En fazla 1 aylık</p>
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        Ziyaret ettiğiniz web sitelerinin geride bıraktığı tüm çerezli üçüncü
        parti yazılımlar aracılığıyla da silebilirsiniz.
      </p>

      <p>
        <strong>Bizimle İletişime Geçin</strong>
        <br />
        <br />
        Politika ile ilgili tüm soru ve görüşlerinizi bize iletmek için&nbsp;
        <a href="/communication" target="_blank" title="İletişim">
          İletişim
        </a>
        &nbsp;sayfamıza göz atın. (trusa.com.tr/iletisim)
      </p>

      <p>
        <strong>Veri Saklama ve İmha Politikası</strong>
      </p>

      <p>
        KVKK Sayfamız:&nbsp;
        <a href="/kvkk-aydinlatma-metni">/kvkk-aydinlatma-metni</a>
      </p>

      <p>
        Bu '<strong>Çerez Politikası</strong>'nın en son güncellendiği tarih:
        09/08/2021
      </p>
    </div>
  );
}

export default CookiePolicy;
