import React from "react";
import "./kurumsal.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import image1 from "../../images/erdemsezer-1.png";
import image2 from "../../images/erdemsezer-2.png";
import exHome from "../../images/trusa-ornek-ev.png";

function Kurumsal() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    fade: true,
  };
  return (
    <>
      <div className="kurumsal-first-area">
        <div className="container kurumsal-first-div">
          <div className="kurumsal-first-div-desc">
            <h2>
              <b>TRUSA'S STORY</b>
            </h2>
            <p>
              A young national athlete who went to America in 1996 to receive
              high school education; After high school, university and business
              life, he stays in America for about 12 years and during this time
              he buys a few houses in America.
              <br />
              <br />
              The rent he receives in dollars is two or almost three times the
              rental income of a house that can be bought for the same price in
              Turkey. Therefore, if he returns to his country, he investigates
              how his rent will be deposited into his account, how and by whom
              his tax will be followed, in short, how he can get his money
              without a headache.
            </p>
          </div>
          <div className="kurumsal-first-div-slick-slide">
            <Slider {...settings}>
              <img src={image1} alt="Erdem Sezer" />
              <img src={image2} alt="Erdem Sezer" />
            </Slider>
          </div>
        </div>
      </div>
      <div className="kurumsal-second-area">
        <div className="kurumsal-second-img">
          <img src={exHome} alt="Örnek Ev" />
        </div>
        <div className="kurumsal-second-desc">
          <p>
            However, when the lawyer, accountant and real estate portfolio
            manager are involved in this research process, respectively, they
            personally witness that this procedure is a very arduous, long and
            costly process.
            <br />
            <br />
            With the idea of making this investment opportunity possible, it
            starts working to bring the TRUSA Investment Management company to
            life, and at the end of a long process, TRUSA Investment Management
            started its activities in the USA in 2005 with its two American
            partners. Between 2005-2015 TRUSA only sells in the USA.
            <br />
            <br /> TRUSA's infrastructure, whose company policy is "Hassle-free
            Investment, Earnings with Foreign Exchange", has come to a point
            where it can only provide tenant guarantee support to all of its
            sales from Turkey in 2015.
            <br />
            <br /> As of 2015, TRUSA, which started to sell houses to its
            customers in Turkey with the option of a rental guarantee in the
            USA, has managed to quickly become the leader of its field in our
            country by bringing together more than 2280 houses with its
            investors in Turkey.
            <br />
            <br /> Note: Since the procedures at the consulate are not visa
            procedures, it is not possible to wait in line. Generally, an
            appointment can be made after 1 day and your procedures at the
            Consulate take about 15 minutes.
          </p>
        </div>
      </div>
    </>
  );
}

export default Kurumsal;
