import React from "react";
import { useNavigate } from "react-router-dom";
import "./blog.scss";
import { blogPage } from "../../helper/blog";

function Blog() {
  let navigate = useNavigate();
  function goDetail(id) {
    navigate(`/blog/${id}`);
  }
  return (
    <div className="container">
      <div className="blog-grid-div">
        {blogPage.map((item) => {
          return (
            <div
              onClick={() => goDetail(item.id)}
              key={item.id}
              className="blog-grid-cell"
            >
              <div className="blog-grid-img">
                <img src={item.image} alt={item.title} />
              </div>
              <div className="blog-grid-content">
                <h5>{item.title}</h5>
                <p>{item.content}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Blog;
