/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./iletisim.scss";
import ofisImg from "../../images/trusa-ofis.jpg";
import axios from "axios";
import Swal from "sweetalert2";

function Iletisim() {
  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
  function sendForm() {
    let syc = 0;
    let veri = new FormData(document.querySelector("form"));
    console.log(Object.fromEntries(veri));
    var formData = new FormData(document.querySelector("form"));
    for (var pair of formData.entries()) {
      if (pair[1] === "") {
        Swal.fire("", "Please fill in the required fields", "error");
        syc++;
      } else if (pair[0] === "eposta" && !validateEmail(pair[1])) {
        Swal.fire("", "Please enter a valid email", "error");
        syc++;
      }
    }

    if (syc === 0) {
      let data = new FormData(document.querySelector("form"));
      axios({
        method: "post",
        url: "https://api.trusa.qa/mail/communication/",
        data: data,
      })
        .then((response) => {
          console.log(response);
          if (response.data.isSuccess) {
            Swal.fire(
              "",
              "Your request has been sent successfully.",
              "success"
            );
          } else {
            Swal.fire(
              "",
              "Your request could not be sent. Please try again later.",
              "error"
            );
          }
        })
        .catch((err) => {
          Swal.fire(
            "",
            "Your request could not be sent. Please try again later.",
            "error"
          );
        });
    }
  }
  return (
    <div className="container">
      <div className="iletisim-area">
        <div className="iletisim-left-content">
          <div className="iletisim-form-div">
            <h3>COMMUNICATION FORM</h3>
            <p>You can send us your message by filling out the form below.</p>
            <form className="iletisim-form">
              <div className="iletisim-form-first">
                <input
                  name="adSoyad"
                  placeholder="Name Surname *"
                  maxLength={20}
                  type="text"
                />
                <input
                  name="eposta"
                  maxLength={50}
                  placeholder="E Mail *"
                  type="text"
                />
                <input
                  name="tel"
                  maxLength={20}
                  placeholder="Phone *"
                  type="number"
                />
                <input
                  name="sehir"
                  maxLength={50}
                  placeholder="City*"
                  type="text"
                />
              </div>
              <div className="iletisim-form-last">
                <textarea
                  name="mesaj"
                  placeholder="Your Message.. *"
                  type="text"
                  maxLength={500}
                />
              </div>
              <div className="submit-div">
                <a onClick={() => sendForm()} className="submit-btn">
                  SEND
                </a>
              </div>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.370020622465!2d51.5187675!3d25.3589125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45e7400e21312d%3A0x31ba46cf55675ea!2sReal%20Estate%20Trusa!5e0!3m2!1str!2str!4v1669914024283!5m2!1str!2str"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </form>
          </div>
        </div>
        <div className="iletisim-right-content">
          <h3>TRUSA Investment Management</h3>
          <p>
            <i>
              <i
                style={{ marginRight: "10px" }}
                className="fa fa-map-marker"
              ></i>
              Manarat Tower, Building no 23, Zone 69 Street 318, 25th Floor,
              <br />
              Lusail, Qatar
              <br />
              <br />
              <i style={{ marginRight: "10px" }} className="fa fa-phone"></i>
              <a style={{ color: "inherit" }} href="tel:+97433030256">
                +974 3303 0256
              </a>
              <br />
              <br />
              <i
                style={{ marginRight: "10px" }}
                className="fa fa-envelope-o"
              ></i>
              <a style={{ color: "inherit" }} href="mailto:info@trusa.com.tr">
                info@trusa.com.tr
              </a>
              <br />
              <br />
              <br />
              <br />
            </i>
          </p>
          <img className="iletisim-ofis-img" src={ofisImg} alt="Trusa Office" />
        </div>
      </div>
    </div>
  );
}

export default Iletisim;
