import React from "react";
import "./pageNotFound.scss";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="notFound">
        <div class="number">404</div>
        <div class="text">
          <span>Ooops...</span>
          <br />
          page not found
          <br />
          <br />
          <button
            onClick={() => {
              navigate("/");
            }}
            className="goHomeButton"
          >
            Go To Home
          </button>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
