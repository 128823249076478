export const faqContent = [
  {
    id: 1,
    title: "Why should I buy a house for investment in America?",
    content:
      " The fact that the rental income of the house you will buy from the USA with TRUSA is 2-3 times higher than that of our country and the prices of investment houses start from as low as $ 60,000 are the main reasons for buying a house for investment in the USA. In addition to these, the fact that the amount of your investment is constantly in foreign currency is an important reason that makes up the logic of the investment.  In other words, if the dollar rate is 3 Liras when you buy your house and 6 Liras when you sell it, you have already received twice the amount you invested in TL when you sold it.  When the rent you collect during this period is added to your earnings, you will see that these two investments provide very different returns from each other.  If we reduce the depreciation period of the houses you will buy from the USA with TRUSA in USD terms as 10-12 years and the alternatives in our country on TL basis between 25-35 years, there will be a significant difference in terms of investment.",
  },
  {
    id: 2,
    title: "Why should I buy a house with TRUSA?",
    content:
      " Almost all of the investment property management in America is carried out by professional management firms.  The reason for this is that one of the terms of the contract is the constant availability of a manager who must be in contact with the tenant in the house. In addition to foreign investors, even the investment houses bought by Americans in their own countries are managed by investment or real estate consultancy firms. TRUSA has been serving in this field since 2005.  As of 2019, with our offices in 5 States of America and 47 employees, we make it possible to collect the incomes of these investments and manage our homes without any problems, with the service we provide to our hosts in this regard. TRUSA, which is unrivaled in its field in Turkey and manages all the houses bought from America with the awareness of its responsibility and brings together the landlords with rental income, manages your home for investment purposes that you will buy from the USA and provides you with issues such as tax, accounting, rent collection, repair, etc. It is the only company that can meet your income without dealing with it. TRUSA has reached an agreement with Garanti Bank and *Türkiye İş Bankası in Turkey for more than 1900 houses that it has mediated in the purchase, and can ensure that the rent is deposited in *Turkey without deducting a commission from the landlord. Due to this infrastructure, team and experience of more than 2280 houses managed, TRUSA is the right choice for the selection, purchase and management of your house from America.",
  },
  {
    id: 3,
    title: "Why Only Existing (used) House?",
    content:
      "Unlike Turkey, there is no discount for purchasing new properties in America or they are not cheaper than existing (used) houses that are in similar condition.On the contrary, the purchaser pays extra for the brand new house that is being bought. As the tax amount will raise accordingly and the net income is the main issue for the investor, buying a brand new house should never be the first option.",
  },
  {
    id: 4,
    title: "“What is Rent Guarantee Option?",
    content:
      "TRUSA has been working with one of the leading insurance companies in America since 2015. With this 25 year agreement, TRUSA customers can purchase a property with the rent guarantee, if they wish, on condition that they pay %8 of their rent each month. This option is one of the most important features that makes TRUSA the leader in its field in Turkey as of today. If you want you can purchase your house without rent guarantee and not pay %8 of your rent.",
  },
  {
    id: 5,
    title: "What is included in the %8 rent guarantee commision?",
    content:
      "%8 commision includes the transfer of your rent to your Turkish Bank Account or abroad on the 7th of each month and your insurance of getting your rent every month in time, even if your tenants can’t pay the rent, pays late or even your house sits vacant for a while. ",
  },
  {
    id: 6,
    title: "From which states can we purcahse a house?",
    content:
      "What is the The strength of TRUSA comes from the fact that it doesn’t annual value and sellthehousesse? it builds, so it assists the investors to buy the rent increa right house with the “know-how” experience of more than 15 years. For that reason you can buy a house from the city you want in the state you want, even from the Street you want. However when it comes to the point of purchasing the houses with the highest rent income, we will provide all the detailed information you will need and the keys of purchasing the right property in our office. ",
  },
  {
    id: 7,
    title: "What does TRUSA commision include?",
    content:
      "TRUSA charges %5 of the sales price of the property as commision for the assistance it provides. This commision includes attorney fees, accountant fees, title registeration fee and real estate commision. During the purchase process you also pay inspection fee for the property you want to buy. This varies around $250-$300 depending on the size of the property and the state.",
  },
  {
    id: 8,
    title: "Visit to the American Consulate;",
    content:
      "We make a short visit to the Notary Department of the US Consulate in Istanbul, with TRUSA, during the process of purchasing your house in America. The purpose of this visit is to prepare the necessary documents that will be needed to get your tax identification number which will match your name with your property. This procedure at the notary will take for about 15 minutes as this department is not in the visa application department,and it is also easy to get an appointment any day.",
  },
  {
    id: 9,
    title: "Is it really that easy?",
    content:
      "Yes.  Everything is ready in TRUSA.  All you have to do is come to our office and choose the house you like from our list.",
  },
  {
    id: 10,
    title: "What if your list doesn't have the house I want?",
    content:
      "The TRUSA real estate investment list covers every home and property type in America.  Our contracted companies are among the leading investment and real estate companies in America.",
  },
  {
    id: 11,
    title: "Still, the house I want is not in TRUSA!?",
    content:
      "TRUSA has an infrastructure that can buy any house, office or land in the USA for you, follow all the procedures for you and finalize the transaction.  After all, you will be able to find any house, office, land or any type of investment you want on TRUSA.",
  },
  {
    id: 12,
    title: "How is the tenant guarantee in every house?",
    content:
      "On the contrary, not every house offers a tenant guarantee. TRUSA, on the other hand, does not sell the houses for which it cannot get insurance guarantee to its investors.  Therefore, all houses brokered in TRUSA are options with a tenant guarantee.We can get a tenant guarantee for only 60-90 of the 300 houses sent monthly to our contracted insurance company.  For this reason, the number of houses we can offer monthly is limited to the number of houses we can get a rental guarantee.",
  },
  {
    id: 13,
    title: "What do I need to do to purchase?",
    content:
      "It is enough to come to our office with your passport and bank account information.  Your rental will be delivered to you through your bank.  Your property title will be issued in your name and will be mailed to your residence address from the state deed notary unit in the USA.  If you want, we can direct your deed to TRUSA and you can get it from our office.  During or after your purchase, you must go to the American Consulate with your passport and get a certified copy of your passport with an apostille.  If you do not have a passport, you can do the same at the American Consulate with your two picture IDs (ID, Driver's License, etc.).  TRUSA will assist you free of charge for all of these processes.",
  },
  {
    id: 14,
    title: "How long will it take to get my house?",
    content:
      "Your home purchase period is completed in approximately 30 days.  This process includes expert visit, proposal, title deed, notary public, accounting works.",
  },
  {
    id: 15,
    title:
      "Who will I talk to after I buy the house and how will I get my rent?",
    content:
      "You will be able to make all your calls with TRUSA.  TRUSA has an infrastructure that can track all tenants and deliver payments to you.",
  },
  {
    id: 16,
    title: "What if I want to live in the house I bought myself?",
    content:
      "If you have such a request, it is sufficient to inform us at the time of purchase or at a later date.  Rental cancellation takes 2 months and then you can stay at home.  If you make such a request during your house purchase, the tenant will vacate the house on the condition of a house purchase agreement and you can sit in your house right after your purchase.",
  },
  {
    id: 17,
    title: "Tax?",
    content:
      "In the United States, you must pay income tax and property tax.  These works can also be followed by TRUSA and paid on your behalf.  For this, TRUSA does not charge you a fee, the payment is made on your behalf and the invoice is delivered to you.  The annual real estate tax on the houses you will buy from America may correspond to your rental income for 3-4 months, but since all the houses you will buy with TRUSA are an investor portfolio, houses with an annual real estate value of 1 month rental income are selected for the TRUSA lists.  Therefore, TRUSA allows you to choose the right house for the fastest return on your investment.",
  },
];
