import img1 from "../images/splide-slider/trusa-ev-fiyatlari-fiyatsiz-09-11135.jpg";
import img2 from "../images/splide-slider/1-1-32819.jpg";
import img3 from "../images/splide-slider/10-1-33052.jpg";
import img4 from "../images/splide-slider/trusa-ev-fiyatlari-fiyatsiz-03-10524.jpg";
import img5 from "../images/splide-slider/trusa-ev-fiyatlari-fiyatsiz-04-10746.jpg";
import img6 from "../images/splide-slider/trusa-ev-fiyatlari-fiyatsiz-08-10925.jpg";
import img7 from "../images/splide-slider/trusa-ev-fiyatlari-fiyatsiz-02-05604.jpg";
import img8 from "../images/splide-slider/6-1-32955.jpg";
import img9 from "../images/splide-slider/trusa-ev-fiyatlari-fiyatsiz-01-04347.jpg";
import img10 from "../images/splide-slider/trusa-ev-fiyatlari-fiyatsiz-05-05817.jpg";
import img11 from "../images/splide-slider/trusa-ev-fiyatlari-fiyatsiz-06-10048.jpg";
import img12 from "../images/splide-slider/trusa-ev-fiyatlari-fiyatsiz-07-10355.jpg";
import img13 from "../images/splide-slider/2-1-32829.jpg";
import img14 from "../images/splide-slider/5-1-32942.jpg";
import img15 from "../images/splide-slider/7-1-33008.jpg";
import img16 from "../images/splide-slider/8-1-33021.jpg";
import img17 from "../images/splide-slider/9-1-33033.jpg";
import img18 from "../images/splide-slider/19-1-30139.jpg";
import img19 from "../images/splide-slider/23-1-30224.jpg";
export const splideSliderPost = [
  {
    id: 1,
    img: img1,
    price: "68,000",
    rent: "671",
    rentGuaranteedNetRent: "617",
    depreciationTime: "9,1",
  },
  {
    id: 2,
    img: img2,
    price: "85,000",
    rent: "800",
    rentGuaranteedNetRent: "736",
    depreciationTime: "9,6",
  },
  {
    id: 3,
    img: img3,
    price: "95,000",
    rent: "900",
    rentGuaranteedNetRent: "828",
    depreciationTime: "9,5",
  },
  {
    id: 4,
    img: img4,
    price: "80,000",
    rent: "772",
    rentGuaranteedNetRent: "710",
    depreciationTime: "9,4",
  },
  {
    id: 5,
    img: img5,
    price: "84,000",
    rent: "785",
    rentGuaranteedNetRent: "722",
    depreciationTime: "9,7",
  },
  {
    id: 6,
    img: img6,
    price: "78,000",
    rent: "735",
    rentGuaranteedNetRent: "677",
    depreciationTime: "9,6",
  },
  {
    id: 7,
    img: img7,
    price: "57,000",
    rent: "531",
    rentGuaranteedNetRent: "489",
    depreciationTime: "9,7",
  },
  {
    id: 8,
    img: img8,
    price: "103,000",
    rent: "700",
    rentGuaranteedNetRent: "644",
    depreciationTime: "13,3",
  },
  {
    id: 9,
    img: img9,
    price: "55,000",
    rent: "518",
    rentGuaranteedNetRent: "477",
    depreciationTime: "9,6",
  },
  {
    id: 10,
    img: img10,
    price: "58,000",
    rent: "541",
    rentGuaranteedNetRent: "498",
    depreciationTime: "9,7",
  },
  {
    id: 11,
    img: img11,
    price: "76,000",
    rent: "723",
    rentGuaranteedNetRent: "666",
    depreciationTime: "9,5",
  },
  {
    id: 12,
    img: img12,
    price: "56,000",
    rent: "525",
    rentGuaranteedNetRent: "483",
    depreciationTime: "9,6",
  },
  {
    id: 13,
    img: img13,
    price: "60,000",
    rent: "600",
    rentGuaranteedNetRent: "552",
    depreciationTime: "9",
  },
  {
    id: 14,
    img: img14,
    price: "78,000",
    rent: "750",
    rentGuaranteedNetRent: "690",
    depreciationTime: "9,4",
  },
  {
    id: 15,
    img: img15,
    price: "85,000",
    rent: "800",
    rentGuaranteedNetRent: "736",
    depreciationTime: "9,6",
  },
  {
    id: 16,
    img: img16,
    price: "70,000",
    rent: "700",
    rentGuaranteedNetRent: "644",
    depreciationTime: "9",
  },
  {
    id: 17,
    img: img17,
    price: "118,000",
    rent: "1050",
    rentGuaranteedNetRent: "966",
    depreciationTime: "10,1",
  },
  {
    id: 18,
    img: img18,
    price: "56,000",
    rent: "530",
    rentGuaranteedNetRent: "487",
    depreciationTime: "9,5",
  },
  {
    id: 19,
    img: img19,
    price: "68,000",
    rent: "650",
    rentGuaranteedNetRent: "598",
    depreciationTime: "9.4",
  },
];
