import React, { useEffect, useState } from "react";
import "./blogDetail.scss";
import { blogPage } from "../../helper/blog";

function BlogDetail() {
  const [blogPost, setBlogPost] = useState("");

  useEffect(() => {
    let id = window.location.pathname.split("/")[2];
    blogPage.forEach((item) => {
      if (item.id == id) {
        setBlogPost(item);
        console.log(item);
      }
    });
    console.log(blogPost);
  }, []); //eslint-disable-line
  return (
    <div className="blog-page container">
      <div className="blog-img">
        <img src={blogPost.image} alt={blogPost.title} />
      </div>
      <div className="blog-desc-area col-lg-10 offset-lg-1">
        <h2>
          <b>{blogPost.title}</b>
        </h2>
        <div
          className="blog-desc-div"
          dangerouslySetInnerHTML={{ __html: blogPost.detailContent }}
        ></div>
      </div>
    </div>
  );
}

export default BlogDetail;
