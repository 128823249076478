import React from "react";
import WSPGallery from "../../components/WSPGallery/WSPGallery";
import "./galeri.scss";
import img1 from "../../images/galeri/img1-45353.jpg";
import img2 from "../../images/galeri/img5-45405.jpg";
import img3 from "../../images/galeri/img2-50100.jpg";
import img4 from "../../images/galeri/img3-50103.jpg";
import img5 from "../../images/galeri/img4-50105.jpg";
import img6 from "../../images/galeri/img5-50109.jpg";
import img7 from "../../images/galeri/img6-50111.jpg";
import img8 from "../../images/galeri/img7-50115.jpg";
import img9 from "../../images/galeri/img8-50118.jpg";
import img10 from "../../images/galeri/b96e1f03-8f61-4e81-931b-d4a7853fc4bf-25227.jpg";
import img11 from "../../images/galeri/6bd763d7-211a-4da1-9f77-fa54c16e8694-00211.jpg";
import img12 from "../../images/galeri/bb379c12-5627-4de3-a208-9c75d50a0912-00213.jpg";
import img13 from "../../images/galeri/577ce3f1-afc8-4c26-85cd-0023582f2032-00222.jpg";

function Galeri() {
  let images = [
    {
      img: img1,
      fullScreenImg: img1,
    },
    {
      img: img2,
      fullScreenImg: img2,
    },
    {
      img: img3,
      fullScreenImg: img3,
    },
    {
      img: img4,
      fullScreenImg: img4,
    },
    {
      img: img5,
      fullScreenImg: img5,
    },
    {
      img: img6,
      fullScreenImg: img6,
    },
    {
      img: img7,
      fullScreenImg: img7,
    },
    {
      img: img8,
      fullScreenImg: img8,
    },
    {
      img: img9,
      fullScreenImg: img9,
    },
    {
      img: img10,
      fullScreenImg: img10,
    },
    {
      img: img11,
      fullScreenImg: img11,
    },
    {
      img: img12,
      fullScreenImg: img12,
    },
    {
      img: img13,
      fullScreenImg: img13,
    },
  ];
  return (
    <div className="container basin-content">
      <h3>BASINDA BİZ</h3>
      <WSPGallery galleryImages={images} />
    </div>
  );
}

export default Galeri;
