import React from "react";
import "./flip.scss";
import flip1prev from "../../images/flip1prev.jpg";
import flip2prev from "../../images/flip2prev.jpg";
import flip3prev from "../../images/flip3prev.jpg";
import flip4prev from "../../images/flip4prev.jpg";
import flip1next from "../../images/flip1next.jpg";
import flip2next from "../../images/flip2next.jpg";
import flip3next from "../../images/flip3next.jpg";
import flip4next from "../../images/flip4next.jpg";

function Flip() {
  return (
    <div className="container">
      <div className="portfoy-title">
        <b>Flip</b>
      </div>
      <span style={{ color: "red" }}>
        <b>
          DUE TO COVID-19, WE HAVE TO GIVE A BREAK OUR WORK FOR A TIME, THANK
          YOU FOR YOUR UNDERSTANDING
        </b>
      </span>
      <p style={{ marginTop: "20px" }}>
        As of 2018, Trusa Homes has started to provide sales services after the
        "Flip" renovation in the State of Florida. As you can see in the
        pictures below, after a noticeable difference as a result of this
        service, it is possible to sell the houses and earn a high price. The
        fact that all services are provided by Trusa’s own employees makes the
        income here logical for the investor. If you wish, we will be pleased to
        make you benefit from this service.
      </p>
      <div className="flip-grid-div">
        <div className="flip-grid-cell">
          <img src={flip1prev} alt="prev" />
        </div>
        <div className="flip-grid-cell">
          <img src={flip1next} alt="prev" />
        </div>
        <div className="flip-grid-cell">
          <img src={flip2prev} alt="prev" />
        </div>
        <div className="flip-grid-cell">
          <img src={flip2next} alt="prev" />
        </div>
        <div className="flip-grid-cell">
          <img src={flip3prev} alt="prev" />
        </div>
        <div className="flip-grid-cell">
          <img src={flip3next} alt="prev" />
        </div>
        <div className="flip-grid-cell">
          <img src={flip4prev} alt="prev" />
        </div>
        <div className="flip-grid-cell">
          <img src={flip4next} alt="prev" />
        </div>
      </div>
    </div>
  );
}

export default Flip;
