import React from "react";
import "./business.scss";
import { Parallax, Background } from "react-parallax";
// import parallaxBgImage from "../../images/businessBanner.jpg";
import parallaxContentImg from "../../images/business/business-line.jpg";
import { business } from "../../helper/business";

function Business() {
  return (
    <>
      <div style={{ position: "relative" }}>
        <Parallax
          pages={3}
          //   bgImage={parallaxBgImage}
          bgImageAlt="city"
          strength={100}
          className="business-parallax"
        >
          <Background className="custom-bg">
            <img src={parallaxContentImg} alt="parallax img" />
          </Background>
          {/* <div className="business-parallax-div">
            <img src={parallaxContentImg} alt="description" />
          </div> */}
        </Parallax>
      </div>
      <div className="container business-grid-area">
        <div className="portfoy-title">
          <b>BUSINESS LINE</b>
        </div>
        <div className="business-grid">
          {business.map((item) => {
            return (
              <div className="business-grid-cell">
                <div className="business-grid-cell-img-div">
                  <img src={item.img} alt="starbucks" />
                </div>

                <div className="business-grid-cell-desc">
                  <h5>{item.title}</h5>
                  <p>
                    <b>Location:</b>
                    {item.location}
                    <br />
                    <b>Sale Price:</b>;${item.salePrice}
                    <br />
                    <b>Rental Income:</b>;***
                    <br />
                    <b>Depreciation Period:</b>***
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Business;
