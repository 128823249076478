/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./faq.scss";
import { faqContent } from "../../helper/faqContent";

function Faq() {
  return (
    <>
      <div className="faq-title">
        <span>ABOUT BUYING A HOME IN AMERICA</span>
        <br />
        FREQUENTLY ASKED QUESTIONS
        <div className="faq-button-div">
          <a className="faq-button">Hemen Bilgi Alın</a>
        </div>
      </div>
      <div className=" container faq-content">
        <div className="faq-left-content">
          <ul>
            {faqContent.map((item) => {
              return item.id <= 10 ? (
                <li key={item.id}>
                  <div className="faq-number">{item.id}</div>
                  <h5>{item.title}</h5>
                  <p>{item.content}</p>
                </li>
              ) : (
                <></>
              );
            })}
          </ul>
        </div>
        <div className="faq-left-content">
          <ul>
            {faqContent.map((item) => {
              return item.id > 10 ? (
                <li key={item.id}>
                  <div className="faq-number">{item.id}</div>
                  <h5>{item.title}</h5>
                  <p>{item.content}</p>
                </li>
              ) : (
                <></>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Faq;
